@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
	--white-color: #ffffff;
	--orange-color: #ff7a00;
	--blue-color: #1191d9;
	--dark-gray-color: #444444;
	--pale-gray-color: #c4c4c4;
	--light-gray-color: #f2f2f2;
	--mid-gray-color: #898989;
	--base-width: 1040px;
	--header-height: 80px;
	--black-color: #000000;
	--green-color: #ddefdd;
	--text-color: #55718a;
}

html,
body {
	z-index: 1;
	background: #e8edf2;
}

* {
	margin: 0px;
	padding: 0px;
	font-family: "Roboto", sans-serif;
}

a {
	text-decoration: none;
	color: inherit;
}

.primary-color {
	color: #5a5a5a;
}

ul {
	list-style: none;
}

input,
textarea {
	background-color: var(--light-gray-color);
	border-radius: 3px;
	border: none;
}

.input-control {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 8px;
	width: 100%;
}

.input-control > label {
	font-size: 14px;
	margin-bottom: 4px;
	font-weight: 400;
}

.input-control > input,
.input-control > textarea {
	font-weight: 300;
	padding: 8px;
	font-size: 14px;
	color: var(--dark-gray-color);
}

.index1 {
	z-index: 1 !important;
}

.no-color-button,
button {
	/* text-transform: uppercase; */
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 4px 16px;
	font-weight: 400;
	font-size: 14px;
}

button {
	box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	color: var(--white-color);
}

.no-color-button {
	color: var(--dark-gray-color);
}

button:hover,
.no-color-button:hover {
	cursor: pointer;
}

.italic-button {
	box-shadow: none;
	color: var(--dark-gray-color);
	padding: 0px;
	background-color: initial;
	text-transform: lowercase;
	font-style: italic;
	text-decoration: underline;
}

.skeleton-button {
	width: 68px;
	height: 18px;
	display: flex;
	border-radius: 10px;
	background-color: var(--light-gray-color);
}

.skeleton-heading {
	width: 240px;
	height: 22px;
	display: flex;
	border-radius: 10px;
	background-color: var(--light-gray-color);
}
.onboadring-process-cont {
	position: absolute;
	overflow: auto;
	top: 0;
	bottom: 0;
	left: 0%;
	right: 0;
	background: #f2f2f2;
	z-index: 10000;
}
/* margins */
.mr-2 {
	margin-right: 2px;
}

.ml0 {
	margin-left: 4px;
}

.ml-0 {
	margin-left: -4px;
}

.ml1 {
	margin-left: 8px;
}

.ml-1 {
	margin-left: -8px;
}

.ml2 {
	margin-left: 12px !important;
}

.ml3 {
	margin-left: 16px;
}

.ml4 {
	margin-left: 20px;
}

.ml5 {
	margin-left: 24px;
}

.ml6 {
	margin-left: 28px;
}

.mb-none {
	margin-bottom: 0px !important;
}

.mt-none {
	margin-top: 0px !important;
}

.mb2px {
	margin-bottom: 2px;
}

.mb0 {
	margin-bottom: 4px;
}

.mb1 {
	margin-bottom: 8px !important;
}

.mb2 {
	margin-bottom: 12px !important;
}

.mb3 {
	margin-bottom: 16px;
}

.mb4 {
	margin-bottom: 20px;
}

.mb5 {
	margin-bottom: 24px;
}

.mb6 {
	margin-bottom: 28px;
}

.mb7 {
	margin-bottom: 32px;
}

.mb8 {
	margin-bottom: 36px;
}

.mb9 {
	margin-bottom: 40px !important;
}

.mr0 {
	margin-right: 4px;
}

.mr1 {
	margin-right: 8px;
}

.mr2 {
	margin-right: 12px !important;
}

.mr3 {
	margin-right: 16px;
}

.mr4 {
	margin-right: 20px;
}

.mr5 {
	margin-right: 24px;
}

.mr6 {
	margin-right: 28px;
}

.mr7 {
	margin-right: 32px;
}

.mr0 {
	margin-right: 4px;
}

.mt-9 {
	margin-top: -9px;
}

.mt-15 {
	margin-top: -15px;
}

.mt0 {
	margin-top: 4px !important;
}

.mt1 {
	margin-top: 8px !important;
}

.mt2 {
	margin-top: 12px !important;
}

.mt3 {
	margin-top: 16px;
}

.mt4 {
	margin-top: 20px;
}

.mt5 {
	margin-top: 24px;
}

.mt6 {
	margin-top: 28px;
}

.mn {
	margin: 0 !important;
}

/* paddings */
.p0 {
	padding: 4px;
}

.p1 {
	padding: 8px !important;
}

.p2 {
	padding: 12px !important;
}

.p3 {
	padding: 16px !important;
}

.p4 {
	padding: 20px;
}

.pn {
	padding: 0 !important;
}

.pl0 {
	padding-left: 4px;
}

.pl1 {
	padding-left: 8px;
}

.pl2 {
	padding-left: 12px;
}

.pl3 {
	padding-left: 16px;
}

.pl4 {
	padding-left: 20px;
}

.pl5 {
	padding-left: 24px;
}

.pl6 {
	padding-left: 28px;
}

.pb2px {
	padding-bottom: 2px;
}

.pb0 {
	padding-bottom: 4px;
}

.pb1 {
	padding-bottom: 8px;
}

.pb2 {
	padding-bottom: 12px;
}

.pb3 {
	padding-bottom: 16px;
}

.pb4 {
	padding-bottom: 20px;
}

.pb5 {
	padding-bottom: 24px;
}

.pb6 {
	padding-bottom: 28px;
}

.pr0 {
	padding-right: 4px;
}

.pr1 {
	padding-right: 8px;
}

.pr2 {
	padding-right: 12px;
}

.pr3 {
	padding-right: 16px;
}

.pr4 {
	padding-right: 20px;
}

.pr5 {
	padding-right: 24px;
}

.pr6 {
	padding-right: 28px;
}

.pr7 {
	padding-right: 32px;
}

.pr8 {
	padding-right: 36px;
}

.pr9 {
	padding-right: 40px;
}

.pr0 {
	padding-right: 4px;
}

.pt0 {
	padding-top: 4px;
}

.pt6px {
	padding-top: 6px;
}

.pt1 {
	padding-top: 8px;
}

.pt2 {
	padding-top: 12px;
}

.pt3 {
	padding-top: 16px;
}

.pt4 {
	padding-top: 20px;
}

.pt5 {
	padding-top: 24px;
}

.pt6 {
	padding-top: 28px;
}

.in-row,
.in-column,
.align-center,
.justify-center,
.justify-end,
.align-start,
.justify-start,
.align-end,
.justify-between,
.justify-evenly {
	display: flex;
}

.align-start {
	align-items: start;
}

.align-end {
	align-items: flex-end;
}

.justify-start {
	justify-content: start;
}

.justify-end {
	justify-content: flex-end;
}

.justify-evenly {
	justify-content: space-evenly !important;
}

.justify-around {
	justify-content: space-around !important;
}

.justify-between {
	justify-content: space-between !important;
}

.initial {
	text-transform: initial !important;
}

.height100 {
	height: 100% !important;
}

.height300 {
	height: 300px;
}

.height80 {
	height: 80vh;
}

.height100vh {
	height: 100vh;
}

.height80vh {
	height: 80vh;
}

.height40 {
	height: 40vh;
}

.height30px {
	height: 30px !important;
}

.height40px {
	height: 40px !important;
}

.height50px {
	height: 50px !important;
}

.height100px {
	height: 100px !important;
}
.height80px {
	height: 80px !important;
}
.height60px {
	height: 60px !important;
}

.height200px {
	height: 200px !important;
}

.height300px {
	height: 300px !important;
}

.height2 {
	height: 2px !important;
}

.height5 {
	height: 5px !important;
}

.height6 {
	height: 6px !important;
}

.height24 {
	height: 24px !important;
}

.height30 {
	height: 30px !important;
}

.height50 {
	height: 50px !important;
}

.mheighti {
	min-height: initial !important;
}
.mheight100px {
	min-height: 100px !important;
}

.capital {
	text-transform: capitalize !important;
}

.lowercase {
	text-transform: lowercase !important;
}

.filter-text {
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	color: #acacac;
	min-width: 60px;
}

.border-bottom {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
	margin: auto;
	border-bottom: 1px solid #e1e1e1;
}
.border-product {
	max-width: 1200px;
	width: 100%;
	height: 0px;
	border: 0.5px solid #000;
	margin: 20px 0;
}

.border-none {
	border: none !important;
}
.border0 {
	border-radius: 0 !important;
}
.bordernone {
	border: none !important;
}
.borderxnone {
	border-left: none !important;
	border-right: none !important;
}
.bordertopnone {
	border-top: none !important;
	border: none !important;
}
.border5 {
	border-radius: 5px !important;
}
.border-active {
	border: 1px solid #299c00 !important;
	border-radius: 5px !important;
}
.border-deactive {
	border: 1px solid #e80000 !important;
	border-radius: 5px !important;
}
.img-border {
	border: 1px solid #e1e1e1;
}
.btn-border {
	border: 1px solid #55718a;
}
.border-custom {
	width: 100%;
	height: 0px;
	border: 0.5px solid #e1e1e1;
}
.border10 {
	border-radius: 10px !important;
}
.text-center {
	text-align: center !important;
}
.text-start {
	text-align: start !important;
}
.text-end {
	text-align: end !important;
}
.text-activated {
	color: #299c00;
}
.text-deactivated {
	color: #e80000;
}

.red {
	color: #af1b3f !important;
}

.light-red {
	color: #e51b15;
}

.green-bg {
	background-color: #ddefdd !important;
}

.reject-color {
	color: #c80000 !important;
}
.progress-color {
	color: #00a02d !important;
}
.redbg {
	background-color: #af1b3f !important;
}

.bluebg {
	background-color: #1e90ff !important;
}

.admin-titlebg {
	background: #f2f2f2 !important;
}

.blue-text {
	color: #006dd2 !important;
}
.pink {
	color: #e901b6 !important;
}
.pink-border {
	border: 1px solid #e901b6;
	border-radius: 5px;
}
.orange-text {
	color: #f1692e !important;
}

.yellow-text {
	color: #ffff00 !important;
}

.grey {
	color: #acacac !important;
}

.grey2 {
	color: #9c9c9c !important;
}

.green {
	color: #00ae3b !important;
}

.greenbg {
	background: #00ae3b !important;
}

.semigreenbg {
	background: #bebebe !important;
}

.blue {
	color: #569bc1 !important;
}

.bluebg {
	background: #569bc1 !important;
}

.semibluebg {
	background: #c4c4c4 !important;
}

.bg-noneactive {
	background-color: #faeded !important;
}

.edit-color {
	background: #ffee96 !important;
}

.in-row {
	flex-direction: row;
}

.in-column {
	flex-direction: column;
}

.p0 {
	padding: 0 !important;
}

.p0div div {
	padding: 0 !important;
}

.p1 {
	padding: 8px;
}

.m0 {
	margin: 0 !important;
}

.m0div div {
	margin: 0 !important;
}

.align-center {
	align-items: center !important;
}

.greybg {
	background: #eeeeee !important;
}

.whitebg {
	background: #ffffff !important;
}

.transperentbg {
	background: transparent !important;
}

.weight500 {
	font-weight: 500;
}

.justify-center {
	justify-content: center;
}

.justify-end {
	justify-content: flex-end;
}

.justify-start {
	justify-content: flex-start;
}

.items-start {
	display: flex;
	align-items: flex-start;
}

.width100 {
	width: 100%;
}

.width90 {
	width: 90%;
}

.width80 {
	width: 80%;
}

.width70 {
	width: 70%;
}

.width60 {
	width: 60%;
}

.width50 {
	width: 50%;
}

.width40 {
	width: 40%;
}

.width30 {
	width: 30%;
}

.width25 {
	width: 25%;
}

.width18 {
	width: 18%;
}

.width13 {
	width: 13%;
}

.width30px {
	width: 30px !important;
}
.width40px {
	width: 40px !important;
}
.width50px {
	width: 50px !important;
}
.width80px {
	width: 80px !important;
}
.mwidth60 {
	min-width: 60px !important;
}
.mwidth80 {
	min-width: 80px !important;
}
.mwidth100 {
	min-width: 100px !important;
}
.mwidth120 {
	min-width: 120px !important;
}

.mwidth150 {
	min-width: 150px !important;
}

.mwidth180 {
	min-width: 180px !important;
}

.mwidth200 {
	min-width: 200px !important;
}

.mwidth220 {
	min-width: 220px !important;
}

.mwidth250 {
	min-width: 250px !important;
}

.mwidth550 {
	min-width: 550px !important;
}

.pointer {
	cursor: pointer;
}

.maxwidth100 {
	max-width: 100px !important;
}

.maxwidth150 {
	max-width: 150px !important;
}

.maxwidth200 {
	max-width: 200px !important;
}

.maxwidth300 {
	max-width: 300px;
}
.maxwidth420 {
	max-width: 420px;
}
.not-allowed {
	cursor: not-allowed;
}
.overflow-wrap {
	word-wrap: break-word;
}
.text-align-left {
	text-align: left;
}

.tooltip-container {
	position: relative;
	display: flex;
}
.sticky {
	top: 0;
	position: sticky;
	z-index: 112;
}

.mheightauto {
	min-height: auto !important;
}

.minheight32 {
	min-height: 32px !important;
}
.minheight50 {
	min-height: 50px !important;
}
.minheight70 {
	min-height: 70px !important;
}

.minheight100 {
	min-height: 100px !important;
}

.minheight150 {
	min-height: 150px !important;
}

.height {
	text-align: center;
}

.tooltip-container .tooltip-content {
	width: 200px;
	height: 165px;
	top: 110%;
	left: 50%;
	margin-left: -100px;
	visibility: hidden;
	color: var(--dark-gray-color);
	font-weight: 150;
	background-color: var(--white-color);
	text-align: center;
	border-radius: 3px;
	position: absolute;
	z-index: -1;
	opacity: 0;
	box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
	-webkit-transition: 0.8s;
	transition: 0.8s;
}

.tooltip-container:hover .tooltip-content {
	visibility: visible;
	z-index: 1000;
	opacity: 1;
}

.opacity0 {
	opacity: 0;
}

.opacity5 {
	opacity: 0.5;
}

.opacity1 {
	opacity: 1;
}

.italic {
	font-style: italic;
}

.ft0 {
	font-size: 10px;
}

.ft1 {
	font-size: 12px;
}

.ft2 {
	font-size: 14px;
}

.ft3 {
	font-size: 18px;
}

.ft4 {
	font-size: 22px;
}

.ft5 {
	font-size: 26px;
}

.ft6 {
	font-size: 30px;
}

.fw0 {
	font-weight: 200;
}

.fw1 {
	font-weight: 300;
}

.fw2 {
	font-weight: 400;
}

.fw3 {
	font-weight: 500;
}

.fw4 {
	font-weight: 600;
}

.fw5 {
	font-weight: 700;
}

.fw6 {
	font-weight: 800;
}

.pdtop {
	padding-top: 60px;
}

.blue-color {
	color: var(--blue-color);
}

.orange-color {
	color: var(--orange-color);
}

.white-color {
	color: var(--white-color);
}

.black-color {
	color: var(--black-color);
}

.dark-gray-color {
	color: var(--dark-gray-color);
}

.light-gray-color {
	color: var(--light-gray-color);
}

.mid-gray-color {
	color: var(--mid-gray-color);
}

.mid-pale-color {
	color: var(--mid-pale-color);
}

.text-color {
	color: var(--text-color);
}

.hover-underline:hover {
	text-decoration: underline;
}

.react-datepicker-popper {
	z-index: 1000;
}

.content-wrapper {
	padding-top: var(--header-height);
}

.white-color {
	color: #ffffff !important;
}

.transparent {
	background-color: transparent !important;
}

.fadein {
	margin-top: 25px;
	font-size: 21px;
	text-align: center;

	-webkit-animation: fadein 0.7s;
	/* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 0.7s;
	/* Firefox < 16 */
	-ms-animation: fadein 0.7s;
	/* Internet Explorer */
	-o-animation: fadein 0.7s;
	/* Opera < 12.1 */
	animation: fadein 0.7s;
}

.underline {
	text-decoration: underline !important;
}

.decoration-none {
	text-decoration: none !important;
}

.overflow {
	overflow: auto;
}

.hidden {
	overflow: hidden;
}

.scroll {
	overflow: scroll;
}

.main-header-cont {
	position: fixed;
	display: flex;
	height: 60px;
	width: 100%;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding-top: 0px;
	z-index: 10;
	background: #ffffff;
}

.pointer {
	cursor: pointer;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.left0 {
	left: 0;
}

.top0 {
	top: 0;
}

.top25 {
	top: 25%;
}

.right25 {
	right: 25%;
}

.right50 {
	right: 50%;
}

.right75 {
	right: 75%;
}
.right85 {
	right: 85%;
}

.left25 {
	left: 25%;
}

.left50 {
	left: 50%;
}

.uppercase-text {
	text-transform: uppercase;
}

.line-height-medium {
	line-height: 20px;
}

.line-height-26 {
	line-height: 26px;
}

.main-body {
	height: auto;
	position: relative;
}

.main-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: -5px;
	position: absolute;
}

.review-margin {
	padding-top: 50px;
}

.no-shadow {
	box-shadow: none !important;
}

.bright-blue-button {
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	text-transform: uppercase;
	color: #006dd2;
	background: #ffffff;
	border: 2px solid #006dd2;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 10px 15px;
}

.vinuus-portal-image {
	margin-right: -10px;
	margin-left: 10px;
}

.bluebg-admin {
	background: #e8edf2 !important;
}

.zIndex10 {
	z-index: 10 !important;
}

.zIndex10000 {
	z-index: 10000 !important;
}

/* .settings-box-element {
    z-index: 10;
}
   */
.buttons-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 240px;
	height: 100%;
}
.text-alignc {
	text-align: center !important;
}
.text-aligns {
	text-align: start !important;
}
.select-option:active {
	font-weight: bold;
}
/* .settings-btnarrow:checked + label {
      display: none;
  }
  .settings-btnarrow:checked ~ .buttons-wrapper {
    left: -240px;
  }
  .settings-btnmain + label {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    cursor: pointer;
    z-index: 11;
    display: none;
  }
  .settings-btnmain:checked + label {
    display: none;
  }

  .settings-btnmain:checked ~ .buttons-wrapper {
    left: 0;
  }

  .settings-btnarrow:checked ~ .settings-btnmain + label {
    display: block;
  }

  .settings-btnmain:checked ~ .settings-btnarrow + label {
    display: block;
  } */
.wrap {
	display: flex;
	flex-wrap: wrap;
}

.width200px {
	width: 200px;
}

.hide {
	display: none;
}

.hide-important {
	display: none !important;
}

.filter-grey {
  filter: brightness(0) saturate(100%) invert(42%) sepia(6%) saturate(2463%) hue-rotate(167deg) brightness(97%) contrast(84%);
}

@media screen and (max-width: 991px) {
	.main-container {
		margin-left: 0;
	}

	.settings-btnarrow + label {
		position: fixed;
		top: 10px;
		left: 220px;
		display: block;
		cursor: pointer;
		z-index: 11;
	}
}

@media screen and (max-width: 769px) {
	.hide7 {
		display: none;
	}
}

@media screen and (max-width: 496px) {
	.search-box {
		margin-left: 10px;
	}

	.mwidth550 {
		min-width: 350px !important;
	}
}

.bold {
	font-weight: bold !important;
}

.normal {
	font-weight: normal !important;
}

.titel_box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}

.vendor-portal {
	padding-right: 10px;
	padding-left: 10px;
	font-family: Helvetica Neue LT Std;
	font-style: normal;
	font-weight: normal;
	font-size: 27px;
	line-height: 32px;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: lowercase;
	color: #ffffff;
	padding-top: 10px;
}

.margin-left5 {
	margin-left: 5px;
}

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

ul {
	display: flex !important;
	flex-direction: column !important;
}

.full-screen {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.center-loader div {
	top: 0;
}

.blink-me {
	animation: blinker 1s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	select:focus,
	textarea:focus,
	input:focus {
		font-size: 14px !important;
		background: #eee;
	}
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	select,
	textarea,
	input {
		font-size: 14px !important;
	}
}

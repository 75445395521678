.contrato-bg {
    z-index: 1100;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    min-height: max-content;
}

.contrato-cont {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 880px;
}

.contrato-file-cont {
    padding: 20 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
   width:'100%';
}

.contrato-choose-file {
    background: #ffffff;
    border: 1px solid #55718A;
    border-radius: 5px;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #55718A;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin: 20px 0;
  }

  .contrato-template-here{
    color: #55718A;
    cursor: pointer;
  }

.contrato-box {
    background: #FFFFFF;
    border-radius: 10px;
    max-height: 600px;
    width: 100%;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: auto;
    padding-bottom: 40px;
}
.policy-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: #5A5A5A;    
}

.debit-text-1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 20px;
    color: #5A5A5A;    
}
.debit-text-2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 25px;
    color: #5A5A5A;    
}

.uploadButton {
    display:'flex'  !important;
    align-items:'center'  !important;
    justify-content:'center'  !important;
    margin-top:20  !important
}
.logout-btn-onboarding {
    border-radius: 6px;
    border: 1px solid #ACACAC;
    background: #EDEBE4;
    color: #5A5A5A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    padding: 10px 20px;
}
@media screen and (max-width: 991px) {
    .contrato-box {
        margin: 10px;
        max-width: 600px;
    }
}
@media screen and (max-width: 769px) {
    .contrato-box {
        max-width: 450px;
    }
}
@media screen and (max-width: 496px) {
    .contrato-box {
        max-width: 280px;
    }
}

.catalogue-cont {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	background: #f2f2f2;
	padding-top: 30px;
}

.catalogue-content-cont {
	width: 70%;
}

.catalogue-content-title,
.catalogue-content-subtitle {
	font-family: "Roboto";
	font-style: normal;
	line-height: 40px;
	color: #5a5a5a;
	margin: 0 auto;
	margin-bottom: 30px;
	text-align: center;
	width: 100%;
}

.catalogue-content-title {
	font-size: 32px;
	font-weight: 300;
}

.catalogue-content-subtitle {
	font-size: 18px;
	font-weight: 400;
}

.catalog-instruction-cont {
	background: #ffffff;
	border: 1px solid #e1e1e1;
	border-radius: 20px;
	margin: 10px 0;
	max-width: 740px;
	padding: 30px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}

.catalog-instruction-steps {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 45%;
}

.catalog-instruction-steps-title,
.catalog-instruction-step {
	font-family: "Roboto";
	font-style: normal;
	color: #5a5a5a;
	margin-bottom: 12px;
}

.catalog-instruction-steps-title {
	font-size: 18px;
	font-weight: 700;
	line-height: 25px;
}

.catalog-instruction-step {
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
}

.download-button,
.import-button {
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	padding: 10px 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.download-button {
	background: #ffffff;
	border: 1px solid #55718a !important;
}

.import-button {
	background: #55718a;
}

.download-button-text,
.import-button-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	color: #ffffff;
	padding-left: 10px;
}

.download-button-text {
	color: #55718a;
}

.import-button-text {
	color: #ffffff;
}

.warning-text {
	font-weight: 400;
	font-size: 12px;
	line-height: 21px;
	color: #5a5a5a;
}

.catalog-watch {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 50%;
}

.catalog-watch-area {
	width: 100%;
	height: 245px;
	position: relative;
	background-color: #f2f2f2;
	display: flex;
	flex-direction: column;
	padding: 10px 0px;
}

.catalog-watch-area-academy {
	padding: 10px 15px;
	background-color: #d9d9d9;
	color: #ffffff;
}

.catalog-watch-area-watchbtn-cont {
	width: 70px;
	border-radius: 30px;
	flex-direction: row;
	padding: 10px 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: auto;
	background: #f1692e;
	color: #ffffff;
}

.сatalog-watch-additional {
	position: absolute;
	left: 20px;
	bottom: 0;
}

.catalog-watch-area-step,
.catalog-watch-area-mycatalog {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 900;
}

.catalog-watch-area-step {
	line-height: 42px;
	color: #c2c2c2;
	font-size: 36px;
}

.catalog-watch-area-mycatalog {
	line-height: 93px;
	color: #e0e0e0;
	font-size: 80px;
}

.catalog-waiting-cont {
	background: #ffffff;
	border: 1px solid #e1e1e1;
	border-radius: 20px;
	max-width: 478px;
	padding: 30px;
	width: 100%;
	margin: 0 auto;
}

.catalog-waiting-title {
	font-weight: 700;
	line-height: 25px;
	color: #5a5a5a;
	font-size: 18px;
}

.catalog-waiting-subtitle {
	font-weight: 400;
	line-height: 21px;
	color: #5a5a5a;
	font-size: 14px;
}


.no-contract-box-container{
    background-color: #E53935;
    height: 65px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 40px;
    color: white;
    justify-content: space-between;
    	font-weight: 500;
	font-size: 15px;
}

.no-contract-box-buttons{
    display: flex;
    align-items: center;
}

.no-contract-box-button{
    height: 40px;
    width: fit-content;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 0 10px;
    border: 1px solid white;
}

.no-contract-box-download-button{
    background-color: #314455;
    height: 40px;
    width: fit-content;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 0 10px;
    margin: 0 20px;
}